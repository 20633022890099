import "./css/ContactSection.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

function Blog6() {
  return (
    <>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <p className={"topic text-bold text-center"}>Contact</p>
        <div className="contact-section">
          <div className="contact-info">
            <h2>Contact Information</h2>
            <div className="info-item">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 6, md: 1 }} sx={{ margin: "auto" }}>
                    <EmailIcon sx={{ fontSize: "30px" }} />
                  </Grid>
                  <Grid size={{ xs: 6, md: 11 }}>
                    <strong style={{ color: "#878787" }}>Email:</strong>
                    <br />
                    <p>anapat@ic21.tech</p>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className="info-item">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 6, md: 1 }} sx={{ margin: "auto" }}>
                    <PhoneIcon sx={{ fontSize: "30px" }} />
                  </Grid>
                  <Grid size={{ xs: 6, md: 11 }}>
                    <strong style={{ color: "#878787" }}>Phone:</strong>
                    <br />
                    <p>+66 (0) 81-415-6614</p>
                    <p>+66 (0) 61-493-5625</p>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className="info-item">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 6, md: 1 }} sx={{ margin: "auto" }}>
                    <FmdGoodIcon sx={{ fontSize: "30px" }} />
                  </Grid>
                  <Grid size={{ xs: 6, md: 11 }}>
                    <strong style={{ color: "#878787" }}>Address:</strong>
                    <p>
                      124 Sukhapiban 5 Road, Tha Raeng Subdistrict, Bang Khen
                      District, Bangkok
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className="social-links">
              <h3>Social Media Links</h3>
              <div className="icons">
                <FacebookIcon />
                <XIcon className={"ml-2"} />
                <LinkedInIcon className={"ml-2"} />
              </div>
            </div>
          </div>
          <div className="contact-form">
            <h2>Contact Form</h2>
            <form>
              <div className="form-group">
                <label>Name</label>
                <input type="text" placeholder="Enter your name" />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input type="email" placeholder="Enter your email" />
              </div>
              <div className="form-group">
                <label>Message</label>
                <textarea placeholder="Enter your message"></textarea>
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </Box>

      <Box sx={{ display: { sm: "none" } }}>
        <p className={"topic text-bold text-center"}>Contact</p>
        <div className="contact-section">
          <div className="contact-info-2">
            <h2>Contact Information</h2>

            <div className="info-item">
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                <EmailIcon sx={{ fontSize: "30px", marginTop: 1 }} />
                <div className={"ml-1"}>
                  <strong style={{ color: "#878787" }}>Email:</strong>
                  <br />
                  <p style={{ fontSize: 15 }}>anapat@ic21.tech</p>
                </div>
              </Box>
            </div>
            <div className="info-item">
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                <PhoneIcon sx={{ fontSize: "30px", marginTop: 2 }} />
                <div className={"ml-1"}>
                  <strong style={{ color: "#878787" }}>Phone:</strong>
                  <br />
                  <p style={{ fontSize: 15 }}>+66 (0) 81-415-6614</p>
                  <p style={{ fontSize: 15 }}>+66 (0) 61-493-5625</p>
                </div>
              </Box>
            </div>
            <div className="info-item">
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                <FmdGoodIcon sx={{ fontSize: "30px", marginTop: 2 }} />
                <div className={"ml-1"}>
                  <strong style={{ color: "#878787" }}>Address:</strong>
                  <p style={{ fontSize: 15 }}>
                    124 Sukhapiban 5 Road, Tha Raeng Subdistrict, Bang Khen
                    District, Bangkok
                  </p>
                </div>
              </Box>
            </div>
            <div className="social-links">
              <h3>Social Media Links</h3>
              <div className="icons">
                <FacebookIcon />
                <XIcon className={"ml-2"} />
                <LinkedInIcon className={"ml-2"} />
              </div>
            </div>
            <h2>Contact Form</h2>
            <div className="contact-form-2">
              <h2>Contact Form</h2>
              <form>
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" placeholder="Enter your name" />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" placeholder="Enter your email" />
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea placeholder="Enter your message"></textarea>
                </div>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}

export default Blog6;
