import "./App.css";
import { Container } from "@mui/material";
import bg from "./assets/BG_2-3.png";
import bgAU from "./assets/BG_Services.png";
import bgOC from "./assets/BG_Contact.png";
import Navbar from "./component/navbar";
import Blog1 from "./page/blog1.js";
import Blog2 from "./page/blog2";
import Blog3 from "./page/blog3";
import Blog6 from "./page/blog6";
import Footer from "./component/footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; 
import Privacy from "./page/privacy";
import About from "./page/about";
import { useRef } from "react";  // Import useRef

function App() {
  const servicesRef = useRef(null);
  const achievementRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <div
                  id="home"
                  className="fullscreen-background"
                  style={{ backgroundImage: `url(${bg})`, paddingTop: "10vh" }}ห
                >
                  <Container maxWidth="xl">
                    <Blog1 
                      scrollToSection={scrollToSection} 
                      servicesRef={servicesRef} 
                      achievementRef={achievementRef} 
                      aboutRef={aboutRef} 
                      contactRef={contactRef}
                    />
                    <div style={{ marginTop: "30rem" }} />
                    <div id="about" ref={aboutRef}>
                      <About />
                    </div>
                  </Container>
                </div>
                <div
                  id="services"
                  ref={servicesRef}
                  className="fullscreen-background-2"
                  style={{
                    backgroundImage: `url(${bgAU})`,
                    paddingTop: "20vh",
                  }}
                >
                  <Container maxWidth="xl">
                    <Blog2 />
                  </Container>
                </div>
                <div
                  id="achievement"
                  ref={achievementRef}
                  className="fullscreen-background-2"
                  style={{
                    backgroundImage: `url(${bgOC})`,
                    paddingTop: "20vh",
                  }}
                >
                  <Container maxWidth="xl" sx={{ paddingBottom: 10 }}>
                    <Blog3 />
                  </Container>
                </div>
                <div
                  id="contact"
                  ref={contactRef}
                  className="fullscreen-background-2"
                  style={{
                    backgroundImage: `url(${bgOC})`,
                    paddingTop: "20vh",
                  }}
                >
                  <Container maxWidth="xl" sx={{ paddingBottom: 10 }}>
                    <Blog6 />
                  </Container>
                </div>
              </div>
            }
          />
          <Route
            path="/privacy"
            element={
              <div
                className="fullscreen-background-2"
                style={{ paddingTop: "15vh" }}
              >
                <Privacy />
              </div>
            }
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
